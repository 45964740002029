import React from "react";
import "./product.css";

const Product = ({ img, link, video }) => {
  const handleVideoClick = () => {
    window.open(video, "_blank"); // Open the video URL in a new window
  };

  return (
    <div className="p">
      <div className="p-browser">
        <div className="p-circle"></div>
        <div className="p-circle"></div>
        <div className="p-circle"></div>
      </div>
      <img
        className="p-img"
        src={img}
        alt=""
        onClick={handleVideoClick} // Add onClick event to open video in a new window
      />
    </div>
  );
};

export default Product;
